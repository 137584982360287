import { getWorkspaceChatbots } from "@/http/api/workspaces";
import { defer, type LoaderFunctionArgs } from "react-router-dom";
import { throwErrorResponse } from "@/helpers";

export default function chatbotsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { workspaceId } = params;
  if (!workspaceId) {
    return throwErrorResponse({
      status: 404,
      statusText: "Not found",
    });
  }
  const response = getWorkspaceChatbots(workspaceId, {
    signal: request.signal,
  }).then((response) => {
    return response.chatbots;
  });
  // Filter out reports from the response
  return defer({ chatbots: response });
}
