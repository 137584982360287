import HttpClient from "@/http/httpClient";
import type {
  PostWorkspaceType,
  PutWorkspaceProps,
  WorkspaceType,
  getWorkspaceChatbotsResponseType,
  getWorkspaceDocumentsResponseType,
  getWorkspaceSourcesResponseType,
} from "./types";

export async function getWorkspaceDocuments(
  workspaceId: string,
  { signal }: { signal?: AbortSignal },
) {
  return HttpClient.request().get<getWorkspaceDocumentsResponseType>(
    `workspaces/${workspaceId}/documents`,
    { signal },
  );
}

export async function getWorkspaceSources(
  workspaceId: string,
  { signal }: { signal?: AbortSignal },
) {
  return await HttpClient.request().get<getWorkspaceSourcesResponseType>(
    `workspaces/${workspaceId}/sources`,
    { signal },
  );
}

export async function getWorkspaceDocumentSources(workspaceId: string) {
  return await HttpClient.request().get<getWorkspaceSourcesResponseType>(
    `workspaces/${workspaceId}/available-sources`,
  );
}

export async function getWorkspaceChatbots(
  workspaceId: string,
  { signal }: { signal?: AbortSignal },
) {
  return await HttpClient.request().get<getWorkspaceChatbotsResponseType>(
    `workspaces/${workspaceId}/chatbots`,
    { signal },
  );
}

export async function postWorkspace(workspace: PostWorkspaceType) {
  return await HttpClient.request().post<WorkspaceType>("workspaces", {
    json: workspace,
  });
}

export async function getWorkspace(
  workspaceId: string,
  { signal }: { signal?: AbortSignal },
) {
  return await HttpClient.request().get<WorkspaceType>(
    `workspaces/${workspaceId}`,
    { signal },
  );
}

export async function putWorkspace(workspace: PutWorkspaceProps) {
  return await HttpClient.request().put<WorkspaceType>(
    `workspaces/${workspace.id}`,
    {
      json: workspace,
    },
  );
}

export async function postWorkspaceLeave(workspaceId: string) {
  return await HttpClient.request().post(`workspaces/${workspaceId}/leave`);
}
