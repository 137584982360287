import { Navigate } from "react-router-dom";

export default function RedirectToLogin() {
  const encodedCurrentPath = encodeURIComponent(
    window.location.pathname + window.location.search + window.location.hash,
  );
  return (
    <Navigate to={`/auth/login?redirect_url=${encodedCurrentPath}`} replace />
  );
}
