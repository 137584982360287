import AuthClient from "@/http/authClient";
import type { PropsWithChildren } from "react";
import RedirectToLogin from "../RedirectToLogin";

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const token = AuthClient.getToken();

  if (!token) {
    return <RedirectToLogin />;
  }

  return children;
};

export default ProtectedRoute;
