import { throwErrorResponse } from "@/helpers";
import { getWorkspace } from "@/http/api/workspaces";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function workspaceSettingsLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    return throwErrorResponse({
      status: 400,
      statusText: "Workspace ID is required",
    });
  }
  const response = await getWorkspace(workspaceId, {
    signal: request.signal,
  });
  return response;
}
