import HttpClient from "@/http/httpClient";
import type { GetRecentItemsResponseType } from "./types";

export async function getRecentItems(
  workspaceId: string,
  { signal }: { signal?: AbortSignal },
) {
  return await HttpClient.request().get<GetRecentItemsResponseType>(
    `workspaces/${workspaceId}/recent-items`,
    { signal },
  );
}
