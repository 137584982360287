import { getWorkspaceDocuments } from "@/http/api/workspaces";
import { defer, type LoaderFunctionArgs } from "react-router-dom";
import { getFilteredData } from "@/helpers";

export default function questionnaireLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    return null;
  }
  const response = getWorkspaceDocuments(workspaceId, {
    signal: request.signal,
  }).then((response) => {
    return getFilteredData({ data: response.documents, route: "documents" });
  });
  // Filter out reports from the response
  return defer({ data: response });
}
