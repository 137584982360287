import type { WorkspaceTypeLite } from "../workspaces/types";

export interface UserType {
  id: string;
  created_at: string;
  updated_at: string;
  email: string;
}

export interface GetUsersPermissionsResponseType {
  workspace_memberships: string[];
  admin_of_workspaces: string[];
  is_company_admin: boolean;
}

export interface GetMeResponseType {
  is_company_admin: boolean;
  workspace_memberships: WorkspaceTypeLite[];
  new_notifications: number;
  email_preferences: EmailNotificationsPreferencesType;
  collect_pii: boolean;
  accessible_features: AppFeatureEnum[];
}

export interface PostAccountPreferencesType {
  emailPreferences: EmailNotificationsPreferencesType;
  collectPii: boolean;
  features: AppFeatureEnum[];
}

export interface EmailNotificationsPreferencesType {
  product_updates_emails: boolean;
  workspaces_updates_emails: boolean;
  reg_updates_regulations_emails: boolean;
  reg_updates_news_emails: boolean;
}

export enum AppFeatureEnum {
  CHATBOTS = "chatbots",
  REGULATORY_HUB = "compliance",
  WORKSPACES = "workspaces",
  COMPANY = "company",
  RESOURCES = "resources",
  REPORTS = "reports",
  QUESTIONNAIRES = "questionnaires",
}
