import BaseHTTPClient from "./baseClient";
import AuthClient from "./authClient";
import { ERROR_NO_TOKEN } from "./constants";

class HttpClient extends BaseHTTPClient {
  protected _authToken: string | null;
  protected _refreshToken: string | null;

  constructor() {
    super();
    const token = AuthClient.getToken();
    const refreshToken = AuthClient.getRefreshToken();

    if (!token && !refreshToken) {
      throw new Error(ERROR_NO_TOKEN);
    }
    this._authToken = token;
    this._refreshToken = refreshToken;

    this._ky = this._ky.extend({
      hooks: {
        beforeRequest: [
          async (request: Request) => {
            try {
              if (this._authToken) {
                request.headers.set("Authorization", this._authToken);
              } else {
                const newToken = await AuthClient.refreshToken();
                // Add the new token to the request headers
                request.headers.set("Authorization", newToken.auth_token);
              }
            } catch (error) {
              console.error(error);
              await AuthClient.logout();
            }
          },
        ],
      },
    });
  }

  static request() {
    return new HttpClient();
  }

  ky() {
    return this._ky;
  }
}

export default HttpClient;
