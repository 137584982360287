/*
 *   Namespaced Cookie Names
 */
export const TOKEN = "docgen_token";
export const REFRESH_TOKEN = "docgen_refreshToken";

export const ERROR_NO_TOKEN_TO_STORE = "No token to store";
export const ERROR_NO_TOKEN = "No token found";
export const ERROR_NO_REFRESH_TOKEN = "No refresh token";
export const ERROR_LOCKED_FILE = "Document is locked";
export const ERROR_NO_WORKSPACE = "No workspace found";
