import { getAdminSurveys } from "@/http/api/admin";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function adminSurveysLoader({
  request,
}: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const param = url.searchParams;
  const action = param.get("action");
  const payload = param.get("payload");
  const requestParams = action && payload ? { action, payload } : undefined;
  const response = await getAdminSurveys(requestParams, {
    signal: request.signal,
  });
  if (action === "export_responses") {
    await handleExportResponse(response);
    return null;
  }
  return response;
}

const handleExportResponse = async (response: Response) => {
  try {
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", getFileNameFromResponse(response));
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (e) {
    alert("Failed to download the file");
  } finally {
    window.location.replace(window.location.pathname);
  }
};

function getFileNameFromResponse(response: Response) {
  const contentDisposition = response.headers.get("Content-Disposition");
  if (contentDisposition) {
    const [, filename] = contentDisposition.match(/filename="(.+)"/) ?? [];
    if (filename) {
      return filename;
    }
  }
  return "export.csv";
}
