import { getRecentItems } from "@/http/api/recent-items";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function homeLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    return null;
  }
  const response = await getRecentItems(workspaceId, {
    signal: request.signal,
  });
  return response.recent_items;
}
