import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@fontsource-variable/dm-sans";
import "./global.mantine.css";
import "@mantine/nprogress/styles.css";
import "@mantine/dropzone/styles.css";
import "mantine-datatable/styles.layer.css";
import { resolver, theme } from "./theme";
import type { PropsWithChildren } from "react";

export default function MantineProviders(props: PropsWithChildren) {
  return (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <Notifications />
      <ModalsProvider>{props.children}</ModalsProvider>
    </MantineProvider>
  );
}
