import { throwErrorResponse } from "@/helpers";
import { getWorkspaceSources } from "@/http/api/workspaces";
import { defer, type LoaderFunctionArgs } from "react-router-dom";

export default function resourcesLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.workspaceId) {
    return throwErrorResponse({
      status: 404,
      statusText: "Workspace Not found",
    });
  }

  const sources = getWorkspaceSources(params.workspaceId, {
    signal: request.signal,
  }).then((response) => {
    return response.sources;
  });

  return defer({ sources });
}
