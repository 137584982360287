import { getDocument } from "@/http/api/document";
import { defer, type LoaderFunctionArgs } from "react-router-dom";
import { setQuestionnairesConversations } from "@/components/Editor/editorAtoms";

export default function questionnaireDetailsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { documentId } = params;
  if (!documentId) {
    return null;
  }
  const questionnaire = getDocument(documentId, {
    signal: request.signal,
  }).then((response) => {
    setQuestionnairesConversations({
      [documentId]: response.conversation_id,
    });

    return response;
  });

  return defer({ loaderDocument: questionnaire });
}
