import { Anchor, AppShell, Image } from "@mantine/core";
import type { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export default function RouteErrorLayout({ children }: PropsWithChildren) {
  return (
    <AppShell
      bg="white"
      header={{
        height: 60,
      }}
    >
      <AppShell.Header bg="transparent" withBorder={false} p="sm">
        <Anchor component={Link} to="/" display="inline-block">
          <Image
            src="/kodex-ai-logo-blue.png"
            fit="contain"
            alt="Kodex AI logo"
            height={48}
          />
        </Anchor>
      </AppShell.Header>
      <AppShell.Main
        display="grid"
        p="sm"
        style={{
          placeItems: "center",
        }}
      >
        {children}
      </AppShell.Main>
    </AppShell>
  );
}
