import HttpClient from "@/http/httpClient";
import type {
  AreaBumpChartData,
  GetInventoryResponseType,
  GetRegulatoryInventoryData,
  GetRegulatorsResponseType,
  GetUpdatesResponseType,
  WelcomeBannerDataResponseType,
  RegHubFeedbackResponseType,
  RegHubFeedbackData,
  RegHubAdditionalFeedbackData,
} from "./types";
import { baseHttp } from "@/http/baseClient";

export async function getRegulators() {
  return await HttpClient.request().get<GetRegulatorsResponseType>(
    "regulatory-updates/regulators",
  );
}

export async function getRegulatoryInventoryData(
  request: GetRegulatoryInventoryData,
) {
  return HttpClient.request().get<GetInventoryResponseType>(
    `regulatory-updates/${request.regulator}/inventory`,
  );
}

export async function getRecentRegulatoryUpdates() {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/recent-updates",
  );
}

export async function getRegulatoryHubNews() {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/news",
  );
}

export async function getFunnelChartData(dateAfter: string) {
  return await HttpClient.request().get<AreaBumpChartData>(
    `regulatory-updates/statistics-by-regulator?date_after=${dateAfter}`,
  );
}

export async function getWelcomeBannerData() {
  return await HttpClient.request().get<WelcomeBannerDataResponseType>(
    "regulatory-updates/welcome",
  );
}

export async function postFeedback(feedbackData: RegHubFeedbackData) {
  return await baseHttp.post<RegHubFeedbackResponseType>(
    "regulatory-updates/feedback",
    {
      json: feedbackData,
    },
  );
}

export async function putFeedback(
  additionalFeedbackData: RegHubAdditionalFeedbackData,
) {
  return await baseHttp.put<RegHubFeedbackResponseType>(
    "regulatory-updates/feedback",
    {
      json: additionalFeedbackData,
    },
  );
}
