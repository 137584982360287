import HttpClient from "@/http/httpClient";

export function getAdminUsers(
  searchParams?: getAdminUsersRequestType,
  options?: { signal?: AbortSignal },
) {
  const http = HttpClient.request();
  const ky = http.ky();
  const response = ky("admin/users", {
    searchParams: searchParams ?? {},
    method: "get",
    signal: options?.signal,
  });
  return response;
}

type getAdminUsersRequestType = { export: string } | { delete: string };

export function getAdminSurveys(
  searchParams?: Record<getAdminSurveysRequestTypeKey, string>,
  options?: { signal?: AbortSignal },
) {
  const http = HttpClient.request();
  const ky = http.ky();
  const response = ky("admin/surveys", {
    searchParams,
    method: "get",
    signal: options?.signal,
  });
  return response;
}

type getAdminSurveysRequestTypeKey = "action" | "payload";
export type getAdminSurveyResponseType = string;
