import { Button, type ButtonProps } from "@mantine/core";

export default function ContactSupport({
  subject,
  ...props
}: ContactSupportProps) {
  return (
    <Button
      variant="light"
      color="neutral"
      {...props}
      component="a"
      target="_blank"
      href={"mailto:hello@kodex-ai.com?subject=" + subject}
    >
      Contact Support
    </Button>
  );
}

interface ContactSupportProps extends ButtonProps {
  subject: string;
}
