import { throwErrorResponse } from "@/helpers";
import { postFeedback } from "@/http/api/regulatory-updates";
import type { RegHubFeedbackResponseType } from "@/http/api/regulatory-updates/types";
import { type LoaderFunctionArgs } from "react-router-dom";

export default async function regHubFeedbackLoader({
  request,
}: LoaderFunctionArgs): Promise<RegHubFeedbackResponseType> {
  const searchParams = new URL(request.url).searchParams;
  const company_id = searchParams.get("company_id");
  const reg_update_id = searchParams.get("reg_update_id");
  const is_relevant = searchParams.get("is_relevant");

  if (company_id && reg_update_id && is_relevant) {
    const response = await postFeedback({
      company_id,
      reg_update_id,
      is_relevant: is_relevant === "true",
    });
    window.history.replaceState(null, "Feedback", "/regulatory-hub/feedback");
    return response;
  } else {
    return throwErrorResponse({
      status: 400,
      statusText:
        "There seems to be missing or faulty data from your feedback.",
    });
  }
}
