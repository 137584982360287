import { atom, getDefaultStore } from "jotai";
import { get, set } from "lodash-es";
import type { SchemaType } from "@/http/api/document/types";
import { nanoid } from "nanoid";
import type { PostAskQuestionResponseType } from "@/http/api/ai/types";
import type { PathType } from ".";

const defaultStore = getDefaultStore();

export const editorSchemaAtom = atom<SchemaType | null>(null);
export const setEditorSchema = (schema: SchemaType | null) => {
  if (schema === null) return;
  defaultStore.set(editorSchemaAtom, schema);
};
const getEditorSchema = () => {
  return defaultStore.get(editorSchemaAtom) ?? null;
};
export const updateEditorSchema = (
  schemaToUpdate: SchemaType,
  path: PathType,
) => {
  const currentSchema = structuredClone(getEditorSchema());
  if (!currentSchema) return;
  if (path.length === 0) {
    setEditorSchema(schemaToUpdate);
    return;
  }
  set(currentSchema, path, schemaToUpdate);
  setEditorSchema(currentSchema);
};
export const deleteEditorSchemaPath = (path: PathType) => {
  const currentSchema = structuredClone(getEditorSchema());
  if (!currentSchema) return;
  if (path.length === 0) {
    return;
  }
  const parentPath = path.slice(0, -1);
  const relevantSection = get(currentSchema, parentPath) as
    | SchemaType[]
    | undefined;
  if (relevantSection) {
    const indexToDelete = path[path.length - 1];
    if (indexToDelete != undefined) {
      relevantSection.splice(Number(indexToDelete), 1);
    }
  }
  setEditorSchema(currentSchema);
};

export const appendQuestionToSchema = ({
  question,
  section,
}: {
  question: string;
  section?: string;
}) => {
  const currentSchema = structuredClone(getEditorSchema());
  if (!currentSchema) return;
  if (!question.trim()) return;
  if (question) {
    const questionSchema = {
      id: nanoid(8),
      content: "",
      content_style: "Heading2",
      question,
      question_style: "Normal",
      children: [],
    };

    if (section) {
      const sectionSchema = {
        id: nanoid(8),
        content: section,
        content_style: "Heading2",
        question: "",
        question_style: "",
        children: [questionSchema],
      };
      currentSchema.children.push(sectionSchema);
    } else {
      const lastSection =
        currentSchema.children[currentSchema.children.length - 1];
      if (lastSection) {
        lastSection.children.push(questionSchema);
      }
    }

    setEditorSchema(currentSchema);
  }
};

// Record<questionnaireID, conversationId>
const questionnairesConversationsAtom = atom<Record<string, string>>({});
export const setQuestionnairesConversations = (
  value: Record<string, string>,
) => {
  defaultStore.set(questionnairesConversationsAtom, value);
};
export const getConversationIdByQuestionnaire = (questionnaireID: string) => {
  return defaultStore.get(questionnairesConversationsAtom)[questionnaireID];
};

export const isAutofillPendingAtom = atom(false);

export const resetGlobalEditorStates = () => {
  setEditorSchema(null);
  defaultStore.set(sectionConversationMapAtom, {});
  defaultStore.set(unansweredSectionIdsAtom, []);
};

export const activeChatSectionAtom = atom<SchemaType | null>(null);

export const questionIdInGenerationAtom = atom<string | null>(null);

export const isQuestionnaireLockedAtom = atom(false);
export const sectionConversationMapAtom = atom<
  Record<string, PostAskQuestionResponseType[]>
>({});

export const addNewSectionConversation = ({
  sectionId,
  response,
}: {
  sectionId: string;
  response: PostAskQuestionResponseType;
}) => {
  const sectionConversationMap = defaultStore.get(sectionConversationMapAtom);
  defaultStore.set(sectionConversationMapAtom, {
    ...sectionConversationMap,
    [sectionId]: [...(sectionConversationMap[sectionId] ?? []), response],
  });
};

export const aiErrorAtom = atom<string | null>(null);
export const setAIError = (value: string | null) => {
  defaultStore.set(aiErrorAtom, value);
};

export const useCaseNameAtom = atom<string>("");

export const editorStreamAtom = atom<string | null>(null);
export const setEditorStream = (value: string | null) => {
  defaultStore.set(editorStreamAtom, value);
};
export const editorStreamStateAtom = atom<null | "pending" | "streaming">(null);
export const setEditorStreamState = (value: null | "pending" | "streaming") => {
  defaultStore.set(editorStreamStateAtom, value);
};

export const unansweredSectionIdsAtom = atom<string[]>([]);
