import HttpClient from "@/http/httpClient";
import type {
  GetMeResponseType,
  GetUsersPermissionsResponseType,
  PostAccountPreferencesType,
} from "./types";
import type { WorkspaceType } from "../workspaces/types";

export async function getUsersWorkspaces({ signal }: { signal?: AbortSignal }) {
  return await HttpClient.request().get<GetUsersWorkspacesResponseType>(
    "users/workspaces",
    { signal },
  );
}

interface GetUsersWorkspacesResponseType {
  workspaces: WorkspaceType[];
}

export async function getUsersPermissions({
  signal,
}: {
  signal?: AbortSignal;
}) {
  return await HttpClient.request().get<GetUsersPermissionsResponseType>(
    "users/permissions",
    { signal },
  );
}

export async function getMe({ signal }: { signal?: AbortSignal }) {
  return await HttpClient.request().get<GetMeResponseType>("users/me", {
    signal,
  });
}

export async function deleteUser(id: string) {
  return await HttpClient.request().delete(`users/${id}`);
}

export async function postAccountPreferences(
  request: PostAccountPreferencesType,
) {
  return HttpClient.request().post("users/me/preferences", {
    json: {
      emails: request.emailPreferences,
      privacy: { collect_pii: request.collectPii },
      features: request.features,
    },
  });
}

export async function postUpdatePassword({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) {
  return HttpClient.request().post("users/me/update-password", {
    json: { old_password: currentPassword, new_password: newPassword },
  });
}

export async function postUpdateEmailAddress(request: {
  new_email: string;
  password: string;
}) {
  return HttpClient.request().post("users/me/update-email", {
    json: request,
  });
}

export async function postDeleteAccount(request: { password: string }) {
  return HttpClient.request().delete("users/me", {
    json: request,
  });
}
