import Cookies from "js-cookie";
import {
  postLogin,
  postRefreshToken,
  postResetPassword,
  postSignUp,
  postResendVerificationEmail,
} from "./api/auth";
import {
  ERROR_NO_REFRESH_TOKEN,
  ERROR_NO_TOKEN_TO_STORE,
  REFRESH_TOKEN,
  TOKEN,
} from "@/http/constants";
import type { loginResponseType, signUpRequestType } from "./api/auth/types";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import { userAtom } from "@/atoms";
import { getDefaultStore } from "jotai";

const defaultStore = getDefaultStore();

const AuthClient = {
  getValueFromCookie(key: CookieKeyType) {
    const value = Cookies.get(key);
    return value ?? null;
  },

  getToken() {
    return this.getValueFromCookie(TOKEN);
  },

  getDecodedToken(jwtToken: string) {
    return jwtDecode<DecodedAuthTokenType>(jwtToken);
  },

  getTokenExpirationDate(auth_token: string) {
    const decodedToken = this.getDecodedToken(auth_token);
    if (!decodedToken.exp) throw new Error("Token has no expiration date");
    return new Date(decodedToken.exp * 1000);
  },

  getRefreshToken() {
    return this.getValueFromCookie(REFRESH_TOKEN);
  },

  clearAllCookies() {
    Cookies.remove(TOKEN);
    Cookies.remove(REFRESH_TOKEN);
  },

  getCookieOptions(cookieType: string, token: string) {
    const expires =
      cookieType === REFRESH_TOKEN ? 1 : this.getTokenExpirationDate(token);

    const options: CookieAttributesType = {
      secure: true,
      sameSite: "strict",
      expires,
    };
    return options;
  },

  saveTokensToCookie(
    cookieTokens: Pick<loginResponseType, "auth_token" | "refresh_token">,
  ) {
    const { auth_token, refresh_token } = cookieTokens;
    if (!auth_token || !refresh_token) {
      throw new Error(ERROR_NO_TOKEN_TO_STORE);
    }

    Cookies.set(TOKEN, auth_token, this.getCookieOptions(TOKEN, auth_token));
    Cookies.set(
      REFRESH_TOKEN,
      refresh_token,
      this.getCookieOptions(REFRESH_TOKEN, refresh_token),
    );

    const decodedToken = this.getDecodedToken(auth_token);
    const { user_id, company_id, email, is_admin } = decodedToken;
    defaultStore.set(userAtom, {
      id: user_id,
      email,
      company_id: company_id,
      isAdmin: is_admin,
    });
  },

  clearSessionStorage() {
    sessionStorage.clear();
  },

  async login(email: string, password: string) {
    try {
      const response = await postLogin(email, password);
      this.saveTokensToCookie(response);
      return await Promise.resolve(response);
    } catch (error) {
      if (error instanceof Error) {
        return Promise.reject(error);
      }
    }
  },

  async refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      throw new Error(ERROR_NO_REFRESH_TOKEN);
    }
    const newToken = await postRefreshToken(refreshToken);
    this.saveTokensToCookie(newToken);
    return newToken;
  },

  logout() {
    try {
      this.clearAllCookies();
      this.clearSessionStorage();

      return Promise.resolve({ success: true });
    } catch (error) {
      if (error instanceof Error) {
        return Promise.reject(error);
      }
    }
  },

  async signup({ email, password, product, utm }: signUpRequestType) {
    try {
      await postSignUp({ email, password, product, utm });
      return await Promise.resolve({ success: true });
    } catch (error) {
      if (error instanceof Error) {
        return Promise.reject(error);
      }
    }
  },

  async resetPassword(email: string) {
    try {
      await postResetPassword(email);
      return await Promise.resolve({ success: true });
    } catch (error) {
      if (error instanceof Error) {
        return Promise.reject(error);
      }
    }
  },

  async resendVerificationEmail(email: string) {
    try {
      await postResendVerificationEmail(email);
      return await Promise.resolve({ success: true });
    } catch (error) {
      if (error instanceof Error) {
        return Promise.reject(error);
      }
    }
  },
};

export default AuthClient;

type CookieKeyType = typeof TOKEN | typeof REFRESH_TOKEN;
type CookieAttributesType = Cookies.CookiesStatic["attributes"];
export interface DecodedAuthTokenType extends JwtPayload {
  company_id: string;
  email: string;
  email_verified: boolean;
  auth_time: number;
  is_admin: boolean;
  user_id: string;
  is_freemium: boolean;
}
