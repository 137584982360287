import { Button, Group } from "@mantine/core";
import RouteErrorCard from "./RouteErrorCard";
import ContactSupport from "../ContactSupport";
import { Link } from "react-router-dom";

function DefaultErrorPage() {
  return (
    <RouteErrorCard
      heading="Unexpected Error"
      subheading="An unexpected error has occurred"
      message="Something went wrong. Please try reloading the page in a little while"
      image="/unexpected-illustration.svg"
    >
      <Group>
        <ContactSupport subject="Unexpected Error" />
        <Button variant="white" component={Link} to={window.location.pathname}>
          Refresh
        </Button>
      </Group>
    </RouteErrorCard>
  );
}

export default DefaultErrorPage;
