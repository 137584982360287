import { baseHttp } from "@/http/baseClient";
import type {
  loginResponseType,
  refreshTokenResponseType,
  signUpRequestType,
} from "./types";

export async function postLogin(email: string, password: string) {
  return baseHttp.post<Promise<loginResponseType>>("auth/login", {
    json: { email, password, product: "app" },
  });
}

export async function postRefreshToken(
  refreshToken: string,
): Promise<refreshTokenResponseType> {
  return baseHttp.post<refreshTokenResponseType>("auth/refresh-token", {
    json: { refresh_token: refreshToken },
  });
}

export async function postSignUp(json: signUpRequestType) {
  return baseHttp.post<Promise<{ message: string }>>("auth/sign-up", {
    json: {
      ...json,
      product_url: window.location.origin,
      product: json.product ?? "app",
    },
  });
}

export async function postResetPassword(email: string) {
  return baseHttp.post<Promise<{ message: string }>>("auth/reset-password", {
    json: { email, product_url: window.location.origin },
  });
}

export async function postResendVerificationEmail(email: string) {
  return baseHttp.post<Promise<{ message: string }>>(
    "auth/resend-verification-email",
    {
      json: { email, product_url: window.location.origin },
    },
  );
}
