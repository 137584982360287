import { throwErrorResponse } from "@/helpers";
import { getChatbotInfo } from "@/http/api/chatbot";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function chatbotPageLoader({
  params,
}: LoaderFunctionArgs) {
  const { chatbotId } = params;
  if (!chatbotId) {
    return throwErrorResponse({
      status: 404,
      statusText: "Not found",
    });
  }

  const response = await getChatbotInfo({
    chatbotID: chatbotId,
  });
  return response;
}
