import { getWelcomeBannerData } from "@/http/api/regulatory-updates";
import { defer } from "react-router-dom";

export default function regulatoryHubLoader() {
  const welcomeBannerPromise = getWelcomeBannerData();

  return defer({
    welcomeBannerData: welcomeBannerPromise,
  });
}
