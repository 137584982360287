import { getWorkspaceDocuments } from "@/http/api/workspaces";
import { defer, type LoaderFunctionArgs } from "react-router-dom";
import { getTemplates } from "@/http/api/templates";
import type { DocumentType } from "@/http/api/document/types";
import { getFilteredData, hasUserAccessToNewsflash } from "@/helpers";

export default function reportsLoader({ request, params }: LoaderFunctionArgs) {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    throw new Error("Workspace ID is required");
  }

  const workspacePromise = getWorkspaceDocuments(workspaceId, {
    signal: request.signal,
  }).then((response) => {
    // Filter out non-reports from the response
    return getFilteredData({
      data: response.documents,
      route: "reports",
    });
  });

  const templatesPromise = getTemplates().then((response) => {
    return hasUserAccessToNewsflash(response.templates);
  });

  return defer({
    reports: workspacePromise,
    hasUserAccessToNewsflash: templatesPromise,
  });
}

export interface ReportLoaderResponseType {
  reports: DocumentType[];
  hasUserAccessToNewsflash: boolean;
}
