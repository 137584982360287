import { userAtom } from "@/atoms";
import { throwErrorResponse } from "@/helpers";
import { getDefaultStore } from "jotai";

const defaultStore = getDefaultStore();

export default function adminLoader() {
  const user = defaultStore.get(userAtom);
  const { email: userEmail } = user;

  const [, domain] = userEmail ? userEmail.split("@") : "";

  if (domain !== "kodex-ai.com") {
    return throwErrorResponse({ status: 404, statusText: "Not found" });
  }
  return null;
}
